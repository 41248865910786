@use "src/Styles/_vars.scss" as c;

.ajout_droits__form {
  margin: unset !important;
  border: 1vh;
  padding: 5svh 5svw;
  width: 100%;
  label {
    margin: 1svh 3vw;
  }
  &-filters {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1svh 0;
    .p-inputtext.p-component {
      min-width: unset !important;
      max-width: 50% !important;
    }
  }
  .p-dropdown {
    width: 50% !important;
  }
  &-content {
    border: 3px solid c.$fond-page !important;
    box-shadow: 0px 2px 4px lightgray !important;
    margin: 1svh 0;
  }
  &-results {
    display: flex;
    justify-content: center;
    margin-bottom: 3svh;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 5vh;
    margin: 0 1vw;
  }
}

// Affichage mobile
@media (max-width: 768px) {
  .ajout_droits__form {
    width: 50%;
    padding: 1svh 1svw;
    label {
      margin: 0.5svh 1vw;
    }
    &-filters {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .p-inputtext {
        min-width: unset !important;
      }
    }
    &-buttons {
    }
  }
}
