@use "src/Styles/vars" as c;


.return-groups {
    margin: 3svh 5svh 0 0;
    display: flex;
    justify-content: right;
}

#header .menu {
  min-height: 0;
}

.group-details {

  &-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5svh 0;
    height: 25svh;
  }
  display: flex;
  max-width: 60%;
  margin: 2rem auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3svh;
  border-radius: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);

  &__header {
    width: 100%;
    &-banner {
      img {
        // Center the image
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        height: 14rem;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
      }
    }

    &-title {
        display: flex;
        justify-content: space-between;
      h2 {
        font-family: c.$font-sous-menu;
        font-size: 1.5rem;
        padding: 1.5rem 0 0.5rem;
        color: c.$bleu-paris1;
      }

      // Buttons to join/leave the group
      .group__join-leave {
        padding: 0.4rem 1rem;
        border-radius: 2rem;
        border: 1px solid c.$bleu-paris1;
        display: flex;
        align-items: center;
        align-self: flex-start;
        margin-top: 1rem;
        color: c.$bleu-paris1;
        background-color: c.$fond-page-clair;
        &:hover {
          background-color: c.$bleu-paris1;
          color: white;
          transition: 0.5s;
          border: 1px solid c.$bleu-paris1;
        }
      }

    }
    &-infos {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin-right: 2rem;
        color: c.$gris-search;
        .icon_infos {
          margin-right: 0.5rem;
        }
        margin-bottom: 0;
      }
      p.click {
        cursor: pointer;
      }

      .btn-blanc {
        width: fit-content;
      }
    }
  }
  &__body {
    margin: 2svh 0;
    &-description {
      h4 {
        margin: 2svh 0;
        font-family: c.$font-sous-menu;
        font-size: 1.15rem;
      }
    }
    &-unauthorized {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 5svh 0;
    }
  }
}

@media (max-width: 768px) {
  .group-details {
    max-width: 90%;
    padding: 1svh;
  }
}